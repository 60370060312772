(function($) {

//******************************************************************************
//* Scroll to anchor
//******************************************************************************
scroll_to_anchor = {
    
    init: function () {
        // Init events  
        this._init_elements();
        this._init_clickevents();

        // Scroll to hash from url
        this._scroll_to_url_hash(500);
    },

    _init_elements: function () {
        $("main.main a[href^='#'], .navbar a[href*='#'], footer a[href*='#']").each(function () {
            
            // Exclude vc accordion
            if ($(this).attr("data-vc-accordion") === undefined) {
                var target_id = $(this).attr("href").substr(1);      
                $(this).attr("data-action", "scroll_to_anchor");
                $(this).attr("data-target-id", target_id);
            }
            
        });

        $("main [id]:not([data-anchor])").each(function () {
            var target_id = $(this).attr("id");
            $(this).attr("data-anchor", target_id);
        });
    },

    _init_clickevents: function () {
        $("[data-action='scroll_to_anchor']").click(function (event) {            
            event.preventDefault();
            history.pushState({}, '', window.location.pathname + '#' + $(this).attr("data-target-id"));
            scroll_to_anchor._scroll_to_url_hash();
        });
    },

    _get_offset: function () {
        return - ($("nav.navbar").height() + 40);
    },

    _scroll_to_url_hash: function (delay) {
        if (window.location.hash > "") {
            delay = delay || 1;

            hash = window.location.hash;
            target = hash.replace("#", '');

            if($('[data-anchor="' + target + '"]').length){
                setTimeout(function () {
                    $.smoothScroll({ 
                        speed: 1000,               
                        scrollTarget: '[data-anchor="' + target + '"]',
                        offset: scroll_to_anchor._get_offset()
                    });
                }, delay);
            }           
        }        
    }
};

})(jQuery); 