(function($) {


//******************************************************************************
//* Grid element
//******************************************************************************
dgmk_publication_list = {
    // TODO: adjust delay
    // TODO: check if and how to generify duplicated code from dgmk_project_grid
    _SEARCH_TEXT_CHANGE_DELAY: 1000,

    last_rest_call_id: 0,

    init: function () {
        this._init_isotope();
        this._init_interface();
    },

    _init_isotope: function () {
        $(".publication_list .grid_wrapper").nd_filtered_isotope({
            filtersInUrl: ["topic", "topicModule", "categories", "search_text"],
            onArrangeComplete: function () {
                dgmk_publication_list._refreshLoadMoreButton($(this));
                dgmk_publication_shopping_cart.refresh();
            },
            onFiltersFromUrlSet: function () {
                dgmk_publication_list._refreshFilterButtonActiveness($(this));
                dgmk_publication_list._refreshTopicModuleSelect2List($(this));
                dgmk_publication_list._refreshTopicModuleSelect2SelectedValues($(this));
                dgmk_publication_list._refreshPublicationCategoryListSelect2SelectedValues($(this));
                dgmk_publication_list._refreshSearchTextField($(this));
            },
            onApplyFilterStarted: function() {
                $(this).find(".loading_pane").addClass("running");
                $(this).parents(".publication_list").find(".no_results_label").hide();
            },
            onApplyFilterEnded: function() {
                $(this).find(".loading_pane").removeClass("running");
            },
            onLoadMoreStarted: function() {
                $(this).parents(".publication_list").find(".show_more_button .loading_spinner").addClass("running");
            },
            onLoadMoreEnded: function() {
                $(this).parents(".publication_list").find(".show_more_button .loading_spinner").removeClass("running");
            },
            customFilter: function(filter, posts_per_page, cur_page) {
                // build REST route with filters
                var url = "/wp-json/filter/publications/";

                // FIXME:
                // the correct language-dependent URL should be built
                // programmatically without the need of multiple conditionals.
                // so we need information about if the current language
                // differs from the default language and if so, we need to
                // easily access the corresponding sub-path to prepend it.
                // if (document.documentElement.lang === "en-US") {
                if ($("body").hasClass("translatepress-en_US")) {
                    url = "/en" + url;
                }

                if (filter.topic !== undefined) {
                    url += filter.topic[0] + "/";
                } else {
                    url += "_/";
                }

                if (filter.topicModule !== undefined) {
                    url += filter.topicModule.join() + "/";
                } else {
                    url += "_/";
                }

                if (filter.categories !== undefined) {
                    url += filter.categories.join() + "/";
                } else {
                    url += "_/";
                }

                if (filter.search_text !== undefined) {
                    url += filter.search_text + "/";
                } else {
                    url += "_/";
                }

                // increment last rest call id
                dgmk_publication_list.last_rest_call_id++;

                // add page number and posts per page to URL as GET parameters
                url += "?" + "page=" + cur_page + "&" + "per_page=" + posts_per_page + "&" + "rest_call_id=" + dgmk_publication_list.last_rest_call_id;

                $.get(url, function(data, status) {
                    // TODO: get filtered isotope for concrete element
                    var filtered_isotope = $(".publication_list .grid_wrapper").nd_filtered_isotope("getInstance");
                    if (parseInt(data.rest_call_id) === dgmk_publication_list.last_rest_call_id) {
                        filtered_isotope.setMoreItemsAvailable(data.hasMoreItems);
                        filtered_isotope.addItems($(data.html));
                    }

                    var no_results_label = $(".publication_list .grid_wrapper .no_results_label");
                    if (data.html) {
                        no_results_label.hide();
                    } else {
                        no_results_label.show();
                    }
                });
            }
        });
    },

    _init_interface: function () {

        // Filter click
        $(".publication_list .filter_button").click(function () {
            var filtered_isotope = dgmk_publication_list._getFilteredIsotopeInstance($(this));
            var topic = $(this).attr("data-filter");
            var filter = filtered_isotope.getFilter();
            
            if ( topic === "all" ) {
                filtered_isotope.clearFilter(["topic", "topicModule"]);
            }
            else {
                filter.topic = [topic];
                delete filter.topicModule;
                filtered_isotope.setFilter(filter);
            }

            // Refresh active class of filter button
            dgmk_publication_list._refreshFilterButtonActiveness($(this));

            // Reinitialize module list dropdown with new list
            dgmk_publication_list._refreshTopicModuleSelect2List($(this));
        }); 

        // Show more
        $(".publication_list .show_more_button").click(function (e) {
            var filtered_isotope = dgmk_publication_list._getFilteredIsotopeInstance($(this));
            filtered_isotope.loadMore();
            e.preventDefault();
        });

        // Topic module list
        $(".publication_list .topic_module_list").select2({
            data: []
        });

        // Publication category list
        $(".publication_list .publication_category_list").select2({
            placeholder: php_to_js.translations.SELECT_PUBLICATION_CATEGORY
        });

        // On topic module select/unselect
        $(".publication_list .topic_module_list").on("select2:select select2:unselect", function(e) {
            var filtered_isotope = dgmk_publication_list._getFilteredIsotopeInstance($(this));
            var filter = filtered_isotope.getFilter();

            // On topic module dropdown filter change
            if ($(e.target).select2("val")) {
                filter.topicModule = [$(e.target).select2("val").join()];
                filtered_isotope.setFilter(filter);
            } else {
                filtered_isotope.clearFilter(["topicModule"]);
            }
        });

        // On publication category dropdown select/unselect
        $(".publication_list .publication_category_list").on("select2:select select2:unselect", function(e) {
            var filtered_isotope = dgmk_publication_list._getFilteredIsotopeInstance($(this));
            var filter = filtered_isotope.getFilter();

            // On publication category dropdown filter change
            if ($(e.target).select2("val")) {
                filter.categories = [$(e.target).select2("val").join()];
                filtered_isotope.setFilter(filter);
            } else {
                filtered_isotope.clearFilter(["categories"]);
            }
        });

        var timeout;
        $(".publication_list [name='search_text']").on("paste keyup", function() {
            var filtered_isotope = dgmk_publication_list._getFilteredIsotopeInstance($(this));
            var filter = filtered_isotope.getFilter();

            var search_text = $(this).val();
            // encode search string and remove slashes to avoid server problems with encoded slashes in URL
            search_text = encodeURIComponent(search_text.replace(/\//g, ""));

            clearTimeout(timeout);
            timeout = setTimeout(function() {
                if (search_text) {
                    filter.search_text = search_text;
                } else {
                    delete filter.search_text;
                }
                filtered_isotope.setFilter(filter);
            }, dgmk_publication_list._SEARCH_TEXT_CHANGE_DELAY);
        });
    },

    _refreshTopicModuleSelect2List: function ($cur_elem) {
        var filtered_isotope = dgmk_publication_list._getFilteredIsotopeInstance($cur_elem);        
        cur_topic_filter = filtered_isotope.getFilter().topic; // Get current topic filter

        var topic_module_list = [];

        if (typeof php_to_js.publication_list_topic_modules !== 'undefined') {
            $.each(php_to_js.publication_list_topic_modules, function(index, topic_module){
                if (cur_topic_filter !== undefined) {
                    if (topic_module.topic === cur_topic_filter[0]) {
                        topic_module_list.push({
                            id: topic_module.slug,
                            text: topic_module.name
                        });
                    }
                } else {
                    topic_module_list.push({
                        id: topic_module.slug,
                        text: topic_module.name
                    });
                }
            });
        }

        $(".publication_list .topic_module_list").select2('destroy').empty();
        $(".publication_list .topic_module_list").select2({
            data: topic_module_list,
            placeholder: php_to_js.translations.SELECT_TECHNICAL_COMMITTEE_WORK_GROUP_TOPIC_MODULE
        });
    },

    _refreshLoadMoreButton: function ($cur_elem) {
        var filtered_isotope = dgmk_publication_list._getFilteredIsotopeInstance($cur_elem);        

        if (filtered_isotope.isMoreItemsAvailable()) {
            this._getGridMainElement($cur_elem).find(".show_more_button").show();
        }
        else {
            this._getGridMainElement($cur_elem).find(".show_more_button").hide();    
        }
    },

    _refreshFilterButtonActiveness: function ($cur_elem) {
        var filtered_isotope = dgmk_publication_list._getFilteredIsotopeInstance($cur_elem);        
        cur_topic_filter = filtered_isotope.getFilter().topic; // Get current topic filter

        var $grid =  this._getGridMainElement($cur_elem); // Get Grid element
        $grid.find(".filter_button_container").removeClass("active");

        // Topic filter
        if (cur_topic_filter !== undefined) {            
            // Set current filter button active
            var $active = $grid.find(".filter_button[data-filter='" + cur_topic_filter[0] + "']");
            if ($active.length) {
                $active.closest('.filter_button_container').addClass("active");
            }
        }
        else {
            // Set "All" button active
            $grid.find(".filter_button[data-filter='all']").closest('.filter_button_container').addClass("active");
        }
    },

    _refreshTopicModuleSelect2SelectedValues: function ($cur_elem) {
        var filtered_isotope = dgmk_publication_list._getFilteredIsotopeInstance($cur_elem);
        cur_topic_module_filter = filtered_isotope.getFilter().topicModule; // Get current topic module filter  
        
        if (cur_topic_module_filter !== undefined) {
            // Set current topic module filter
            var selected_topic_module = cur_topic_module_filter[0].split(',');
            $(".publication_list .topic_module_list").val(selected_topic_module).trigger('change');
        }
    },

    _refreshPublicationCategoryListSelect2SelectedValues: function ($cur_elem) {
        var filtered_isotope = dgmk_publication_list._getFilteredIsotopeInstance($cur_elem);
        cur_categories_filter = filtered_isotope.getFilter().categories; // Get current publication category filter  
        
        if (cur_categories_filter !== undefined) {
            // Set current publication category filter
            var selected_publication_categories = cur_categories_filter[0].split(',');
            $(".publication_list .publication_category_list").val(selected_publication_categories).trigger('change');
        } else {
            $(".publication_list .publication_category_list").val([]).trigger('change');
        }
    },

    _refreshSearchTextField: function ($cur_elem) {
        var filtered_isotope = dgmk_publication_list._getFilteredIsotopeInstance($cur_elem);
        cur_search_text = filtered_isotope.getFilter().search_text;
        
        if (cur_search_text !== undefined) {
            var search_text = decodeURIComponent(cur_search_text);
            $(".publication_list [name='search_text']").val(search_text).trigger('change');
        }
    },

    _getGridMainElement: function ($cur_elem) {
        return $cur_elem.parents(".publication_list");
    },

    _getFilteredIsotopeInstance: function ($cur_elem) {
        return this._getGridMainElement($cur_elem).find(".grid_wrapper").nd_filtered_isotope("getInstance");
    }
};

})(jQuery);

