(function($) {

//******************************************************************************
//* Helper for Visual Composer rendering
//******************************************************************************
nd_vc_render_helper = {

    init: function () {

    	// Listen for VC vc_js event and trigger our custom event
    	$(document).on('vc_js', function () {
    		$( window ).trigger( "nd_vc_render_ready" );
        });

        // Listen for vc-full-width-row-single event everytime it finishes calculating row width and trigger our custom event 
        $(document).on('vc-full-width-row-single', function () {
            $( window ).trigger( "nd_vc_row_ready" );
        });
    }
};

})(jQuery); 