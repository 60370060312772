(function($) {


//******************************************************************************
//* Grid element
//******************************************************************************
dgmk_event_list = {
    init: function () {
        this._init_isotope();
        this._init_interface();
        this._showFutureEvents();
    },

    _init_isotope: function () {
        $(".event_list .grid_wrapper").nd_filtered_isotope({
            filtersInUrl: ["topic", "topicModule", "year", "futureEvent"],
            onArrangeComplete: function () {
                dgmk_event_list._refreshLoadMoreButton($(this));

                var element = $(this);
                // apply same height group once all images are loaded
                $(this).imagesLoaded()
                    .always( function( instance ) {
                        same_height_groups.refresh();
                        element.isotope("layout");
                    });
            },
            onFiltersFromUrlSet: function () {
                dgmk_event_list._refreshFilterButtonActiveness($(this));
                dgmk_event_list._refreshTopicModuleSelect2List($(this));
                dgmk_event_list._refreshTopicModuleSelect2SelectedValues($(this));
                dgmk_event_list._refreshYearListSelect2SelectedValues($(this));
            }
        });
    },

    _init_interface: function () {

        // Filter click
        $(".event_list .filter_button").click(function () {
            var filtered_isotope = dgmk_event_list._getFilteredIsotopeInstance($(this));
            var topic = $(this).attr("data-filter");
            
            if ( topic === "all" ) {
                filtered_isotope.clearFilter(["topic", "topicModule", "year"]);
                filtered_isotope.setFilter({
                    futureEvent: ["true"]
                });
            }
            else {
                var cur_filter = filtered_isotope.getFilter();

                cur_filter.topic = [topic];
                delete cur_filter.topicModule;
                filtered_isotope.setFilter(cur_filter);
            }

            // Refresh active class of filter button
            dgmk_event_list._refreshFilterButtonActiveness($(this));

            // Reinitialize module list dropdown with new list
            dgmk_event_list._refreshTopicModuleSelect2List($(this));

            // refresh year selection UI
            dgmk_event_list._refreshYearListSelect2SelectedValues($(this));
        }); 

        // Show more
        $(".event_list .show_more_button").click(function (e) {
            var filtered_isotope = dgmk_event_list._getFilteredIsotopeInstance($(this));
            filtered_isotope.loadMore();
            e.preventDefault();
        });

        // Topic module list
        $(".event_list .topic_module_list").select2({
            data: []
        });

        // Year list
        $(".event_list .event_year_list").select2({
            placeholder: php_to_js.translations.SELECT_EVENT_YEAR
        });

        // On topic module select/unselect
        $(".event_list .topic_module_list").on("select2:select select2:unselect", function(e) {
            var filtered_isotope = dgmk_event_list._getFilteredIsotopeInstance($(this));

            // On topic module dropdown filter change
            if ($(e.target).select2("val")) {
                var filter = filtered_isotope.getFilter();
                filter.topicModule = [$(e.target).select2("val").join()];

                filtered_isotope.setFilter(filter);
            } else {
                filtered_isotope.clearFilter(["topicModule"]);
            }
        });

        // On year select/unselect
        $(".event_list .event_year_list").on("select2:select select2:unselect", function(e) {
            var filtered_isotope = dgmk_event_list._getFilteredIsotopeInstance($(this));

            // On year dropdown filter change
            var filter = filtered_isotope.getFilter();
            if ($(e.target).select2("val")) {

                // Delete futureEvent filter if year filter is set
                cur_future_event_filter = filtered_isotope.getFilter().futureEvent;
                if (cur_future_event_filter !== undefined) {
                    delete filter.futureEvent;
                }

                filter.year = [$(e.target).select2("val").join()];

                filtered_isotope.setFilter(filter);
            } else {
                delete filter.year;
                dgmk_event_list._showFutureEvents();
            }
        });
    },

    _showFutureEvents: function () {
        if ($(".event_list .grid_wrapper").length > 0) {
            var filtered_isotope = $(".event_list .grid_wrapper").nd_filtered_isotope("getInstance");

            cur_year_filter = filtered_isotope.getFilter().year;

            // Show future event if no year is set
            if (cur_year_filter === undefined) {
                var filter = filtered_isotope.getFilter();
                filter.futureEvent = ['true'];

                filtered_isotope.setFilter(filter);
            }
        }
    },

    _refreshTopicModuleSelect2List: function ($cur_elem) {
        var filtered_isotope = dgmk_event_list._getFilteredIsotopeInstance($cur_elem);        
        cur_topic_filter = filtered_isotope.getFilter().topic; // Get current topic filter

        var topic_module_list = [];

        if (typeof php_to_js.event_list_topic_modules !== 'undefined') {
            $.each(php_to_js.event_list_topic_modules, function(index, topic_module){
                if (cur_topic_filter !== undefined) {
                    if (topic_module.topic === cur_topic_filter[0]) {
                        topic_module_list.push({
                            id: topic_module.slug,
                            text: topic_module.name
                        });
                    }
                } else {
                    topic_module_list.push({
                        id: topic_module.slug,
                        text: topic_module.name
                    });
                }
            });
        }

        $(".event_list .topic_module_list").select2('destroy').empty();
        $(".event_list .topic_module_list").select2({
            data: topic_module_list,
            placeholder: php_to_js.translations.SELECT_TECHNICAL_COMMITTEE_WORK_GROUP_TOPIC_MODULE
        });
    },

    _refreshLoadMoreButton: function ($cur_elem) {
        var filtered_isotope = dgmk_event_list._getFilteredIsotopeInstance($cur_elem);        

        if (filtered_isotope.isMoreItemsAvailable()) {
            this._getGridMainElement($cur_elem).find(".show_more_button").show();
        }
        else {
            this._getGridMainElement($cur_elem).find(".show_more_button").hide();    
        }
    },

    _refreshFilterButtonActiveness: function ($cur_elem) {
        var filtered_isotope = dgmk_event_list._getFilteredIsotopeInstance($cur_elem);        
        cur_topic_filter = filtered_isotope.getFilter().topic; // Get current topic filter

        var $grid =  this._getGridMainElement($cur_elem); // Get Grid element
        $grid.find(".filter_button_container").removeClass("active");

        // Topic filter
        if (cur_topic_filter !== undefined) {            
            // Set current filter button active
            var $active = $grid.find(".filter_button[data-filter='" + cur_topic_filter[0] + "']");
            if ($active.length) {
                $active.closest('.filter_button_container').addClass("active");
            }
        }
        else {
            // Set "All" button active
            $grid.find(".filter_button[data-filter='all']").closest('.filter_button_container').addClass("active");
        }
    },

    _refreshTopicModuleSelect2SelectedValues: function ($cur_elem) {
        var filtered_isotope = dgmk_event_list._getFilteredIsotopeInstance($cur_elem);
        cur_topic_module_filter = filtered_isotope.getFilter().topicModule; // Get current topic module filter  
        
        if (cur_topic_module_filter !== undefined) {
            // Set current topic module filter
            var selected_topic_module = cur_topic_module_filter[0].split(',');
            $(".event_list .topic_module_list").val(selected_topic_module).trigger('change');
        }
    },

    _refreshYearListSelect2SelectedValues: function ($cur_elem) {
        var filtered_isotope = dgmk_event_list._getFilteredIsotopeInstance($cur_elem);
        cur_year_filter = filtered_isotope.getFilter().year; // Get filter year  
        
        if (cur_year_filter !== undefined) {
            // Set year filter
            var selected_years = cur_year_filter[0].split(',');
            $(".event_list .event_year_list").val(selected_years).trigger('change');
        } else {
            $(".event_list .event_year_list").val([]).trigger('change');
        }
    },

    _getGridMainElement: function ($cur_elem) {
        return $cur_elem.parents(".event_list");
    },

    _getFilteredIsotopeInstance: function ($cur_elem) {
        return this._getGridMainElement($cur_elem).find(".grid_wrapper").nd_filtered_isotope("getInstance");
    }
};

})(jQuery);
