(function($) {



//******************************************************************************
//* Projects carousel
//******************************************************************************
dgmk_project_carousel = {

    init: function () {
        $(document).ready(function(){
            $('.projects_carousel').slick({
                speed: 1000,
                slidesToShow: 3,
                slidesToScroll: 3,
                responsive: [
                    {
                        breakpoint: 1100,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 2
                        }
                    },
                    {
                        breakpoint: 600,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1
                        }
                    }
                ]
            });
        });
    }

};

})(jQuery);