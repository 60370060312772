(function($) {


//******************************************************************************
//* Grid element
//******************************************************************************
nd_vc_row = {
    init: function () {
        nd_vc_row.add_overflow_visible_class_to_parent_row();
    },

    add_overflow_visible_class_to_parent_row: function () {
        $('[data-add-overflow-visible-class-to-parent-row="true"]').closest('.vc_row').addClass('overflow_visible');
    }
};

})(jQuery);
