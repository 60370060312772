(function($) {
    dgmk_people_teaser = {
    
        init: function () {
            this._init_events();
        },
    
        _init_events: function () {
            $('.people-teaser .excerpt__show-more').on('click', function() {
                $(this).parent().toggleClass('excerpt--opened');
            });
        }
    };    
})(jQuery);
    