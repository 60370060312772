(function($) {


//******************************************************************************
//* Anchor navigation
//******************************************************************************
dgmk_anchor_navigation = {

    init: function () {
        this._add_anchor_attribute_to_content();
        this._add_anchor_element();

        scroll_to_anchor.init();
    },

    _add_anchor_attribute_to_content: function () {
        $('main.main .data_add_anchor_from_content').each(function() {
            var name = $(this).text();
            var slug = dgmk_tools.slugify(name);

            $(this).attr('data-anchor-navigation-item', name);
            $(this).attr('data-anchor', slug);
        });

    },

    _add_anchor_element: function () {
        if ( $('main.main [data-anchor-navigation-item]').length > 0 ) {


            $('main.main [data-anchor-navigation-item]').each(function() {
                var name = $(this).attr('data-anchor-navigation-item');
                var slug = $(this).attr('data-anchor');

                $('.anchor_navigation').append("<a class='topic-color-hover' href='#" + slug + "'>" + name + "</a>");
            });
        } else {
            $('.anchor_navigation').addClass('mb-0');
        }
    }
};

})(jQuery);
