(function($) {



//******************************************************************************
//* Publication shopping cart
//******************************************************************************
dgmk_publication_shopping_cart = {
    init: function () {
        this.refresh();
    },

    refresh: function() {
        this._init_events();
        this._refresh_publication_cart_visibility_and_publication_count();
        this._add_exist_data_attr_for_publication_which_is_in_cart();
    },

    _init_events: function () {
        $('[data-publication-id] .purchase').not(".initialized").click(function(event) {
            event.preventDefault();

            var publication = {
                'id': $(this).closest('.publication').attr('data-publication-id'),
                'number': $(this).closest('.publication').attr('data-publication-number'),
                'name': $(this).closest('.publication').attr('data-publication-name'),
                'price': parseFloat($(this).closest('.publication').attr('data-publication-price')),
                'no_discount_for_dgmk_members': $(this).closest('.publication').attr('data-no-discount-for-dgmk-members'),
                'disclaimer_required': $(this).closest('.publication').attr('data-disclaimer-required'),
                'language': $(this).closest('.publication').attr('data-publication-language'),
            };

            // Update publication in cart
            dgmk_publication_shopping_cart.add_or_update_publication(publication);
            
            // Show message after updating publication
            dgmk_publication_shopping_cart._display_modal_message(publication);

            // Add 'data-exist-in-cart' attribute for publication which is in cart
            dgmk_publication_shopping_cart._add_exist_data_attr_for_publication_which_is_in_cart();
        }).addClass("initialized");
    },

    _is_in_cart: function (publication_id) {
        var publications = dgmk_publication_shopping_cart.get_publications_from_cookie();
        var exist_in_cart = false;

        $.each( publications, function( index, publication ) {
            if (publication.id === publication_id) {
                exist_in_cart = true;
            }
        });

        return exist_in_cart;
    },

    _get_publication_amount: function (publication_id) {
        var publications = dgmk_publication_shopping_cart.get_publications_from_cookie();
        var publication = publications.find(function( publication ) {
            return publication.id === publication_id;
        });

        return publication.amount + 1;
    },

    _display_modal_message: function () {
        $('#shopping_cart_modal_message').modal();
    },

    _add_exist_data_attr_for_publication_which_is_in_cart: function () {
        var publications = dgmk_publication_shopping_cart.get_publications_from_cookie();

        $.each( publications, function( index, publication ) {
            $('.publication[data-publication-id="' + publication.id + '"]').attr('data-exist-in-cart', true);
        });
    },

    _refresh_publication_cart_visibility_and_publication_count: function () {
        var publications = dgmk_publication_shopping_cart.get_publications_from_cookie();

        // Publication count
        $('[data-section="shopping_cart_items"] .item_count').text(publications.length);

        // Icon visibility
        if (publications.length === 0) {
            $('[data-section="shopping_cart_items"]').addClass('d-none');
        } else {
            $('[data-section="shopping_cart_items"]').removeClass('d-none');
        }
    },    

    get_publications_from_cookie: function () {
        if (Cookies.get('publications')) {
            return JSON.parse(Cookies.get('publications'));
        } else {
            return [];
        }
    },

    add_or_update_publication: function (publication) {
        var publications = dgmk_publication_shopping_cart.get_publications_from_cookie();

        if (dgmk_publication_shopping_cart._is_in_cart(publication.id)) {
            var index = publications.map(function(publication) { return publication.id; }).indexOf(publication.id);

            // Get publication amount from cookie if no amount exist in given publication
            if (!publication.hasOwnProperty('amount')){         
                publication.amount = dgmk_publication_shopping_cart._get_publication_amount(publication.id);
            }
            
            // Replace in cart
            publications[index] = publication;
        }
        else {
            // Add to cart
            publication.amount = 1;
            publications.push(publication);
        }

        // Update publications cookie
        Cookies.set('publications', JSON.stringify(publications));

        // Refresh shopping cart icon visibility and update publication count
        dgmk_publication_shopping_cart._refresh_publication_cart_visibility_and_publication_count();
    },

    remove_publication: function (publication_id) {
        var publications = dgmk_publication_shopping_cart.get_publications_from_cookie();
        var index = publications.map(function(publication) { return publication.id; }).indexOf(publication_id);

        // Remove from cart
        publications.splice(index, 1);

        // Update publications cookie
        Cookies.set('publications', JSON.stringify(publications));

        // Refresh shopping cart icon visibility and update publication count
        dgmk_publication_shopping_cart._refresh_publication_cart_visibility_and_publication_count();
    }
};

})(jQuery);