(function($) {

// https://github.com/jnicol/jquery-plugin-boilerplate/

//******************************************************************************
//* Grid element
//******************************************************************************

var pluginName = 'nd_filtered_isotope';

function Plugin(element, options) {
    // Instance variables
    var el = element;
    var $el = $(element);
    var cur_filter = {};
    var cur_page = 1;
    var moreItemsAvailable = false;
    var itemsLoaded = false;
    var loadMoreRunning = false;

    options = $.extend({}, $.fn[pluginName].defaults, options); // Options are extended by default

    // Returns true if there are more items available than currently visible
    function isMoreItemsAvailable() {
        return Boolean($el.find(".visible_by_filter:not(.visible_by_page)").length) || moreItemsAvailable;
    }

    // Load next page
    function loadMore() {        
        _hook('onLoadMoreStarted');
        loadMoreRunning = true;
        cur_page++;
        itemsLoaded = false;
        _applyFilterAndPagination();
    }

    // Add new items to the grid.
    // If the current page equals 1, all items are cleared before the new ones are added.
    function addItems($newItems) {
        if (cur_page === 1) {
            _clearItems();
        }

        $el.append($newItems).isotope("addItems", $newItems);

        // refresh isotope
        $el.isotope({ filter: '*' });

        _setUrlFromFilter();
        if (loadMoreRunning) {
            _hook('onLoadMoreEnded');
            loadMoreRunning = false;
        } else {
            _hook('onApplyFilterEnded');
        }
    }

    // Clear all current items
    function _clearItems() {
        var items = $el.find(".grid_item");
        $el.isotope("remove", items);
    }

    // Set filter json to given filter
    function setFilter(filter, do_not_change_url) {
        do_not_change_url = do_not_change_url || false;   
        cur_filter = filter;
        _resetPage();
        _applyFilterAndPagination(do_not_change_url);
    }

    // Add value for a specific key in cur_filter
    function addFilterValue(filter_key, filter_value) {
        cur_filter[filter_key] = filter_value;
        _resetPage();
        _applyFilterAndPagination();
    }

    // Remove specific key in cur_filter
    function clearFilter(filter_keys) {
        filter_keys.forEach( function(filter_key) { 
            if (cur_filter.hasOwnProperty(filter_key)) {
               delete cur_filter[filter_key];
            }
        });
        _resetPage();
        _applyFilterAndPagination();
    }

    // Sort by given key or key list (initialise keys with getSortData: https://isotope.metafizzy.co/sorting.html#getsortdata)
    function sortBy(sortby) {
        _resetPage();
        $el.isotope({ sortBy: sortby });
    }  

    // Get current plugin instance
    function getInstance() {
        return $.data(this, 'plugin_' + pluginName);
    }

    // Get current filter
    function getFilter() {
        return cur_filter;
    }    

    // Allows to set if more items are available,
    // e.g. when using a custom filter.
    function setMoreItemsAvailable(val) {
        moreItemsAvailable = val;
    }

    // Destroy plugin instance
    function destroy() {
        $el.each(function() {
            var el = this;
            var $el = $(this);

            _hook('onDestroy');
            $el.removeData('plugin_' + pluginName);
        });
    }

    // Execute hook (see plugin boilerplate docs)
    function _hook(hookName) {
        if (options[hookName] !== undefined) { options[hookName].call(el); }
    }

    // Called on initialisation
    function _init() {
        // Init isotope
        var isotope = $el.isotope({
            itemSelector: '.grid .grid_item',
            layoutMode: 'fitRows',
            getSortData: options.getSortData   
        });

        if (options.customFilter !== undefined) {
            $el.attr("data-custom-filter", true);
        }

        // Init event callbacks
        _init_events(isotope);

        // Apply same height groups
        same_height_groups.refresh();

        // Trigger onInit Hook
        _hook('onInit');

        // Set filter from URL
        _setFilterFromUrl();

        // Apply filter and pagination
        _applyFilterAndPagination(true);
    }

    // Init event callbacks
    function _init_events(isotope) {
        // Trigger isotopeArrangeComplete hook        
        isotope.on('arrangeComplete', function () {
            // Detach process with setTimeout, otherwise we get problems if we try to access plugin instance in an onArrangeComplete event
            setTimeout(function () { _hook('onArrangeComplete'); }, 1);            
        });

        // HTML pop state event sets filter
        window.addEventListener('popstate', function(event) {
            setFilter(event.state.filters, true);

            // Trigger onFiltersFromUrlSet event
            _hook('onFiltersFromUrlSet');
        }, false);     
        
        // Apply filter and pagination on window resize and after vc layout finished 
        $(window).on('nd_resize_ready nd_vc_render_ready nd_vc_row_ready', function() {
            same_height_groups.refresh();
            isotope.isotope("layout");
        });            
    }

    function _applyFilterAndPagination(do_not_change_url) {
        if (!loadMoreRunning) {
            _hook('onApplyFilterStarted');
        }
        if (options.customFilter !== undefined) {
            if (!itemsLoaded) {
                options.customFilter(cur_filter, options.itemsPerPage, cur_page);
                itemsLoaded = true;
            }
        } else {
            _applyFilterAndPaginationDefault(do_not_change_url);
            _hook('onApplyFilterEnded');
        }
    }

    // Apply cur_filter and cur_page and refresh grid
    function _applyFilterAndPaginationDefault(do_not_change_url) {
        do_not_change_url = do_not_change_url || false;        
        $el.isotope({ filter: '*' });

        _applyFilterClass();
        _applyPaginationClass();

        $el.isotope({ filter: '.visible_by_filter.visible_by_page' });

        if (!do_not_change_url) {
            _setUrlFromFilter();
        }
    }

    // Set filter params from url
    function _setFilterFromUrl() {
        // Set current filter if filter exist in url
        if (window.location.href.search('filter/') > -1) {
            var url = window.location.href.substr(0, window.location.href.indexOf('filter/'));
            var filter_string = window.location.href.replace(url + 'filter/', '' ).replace(/\/$/, "");
            var filter_list = filter_string.split('/');
            
            // Set filter with respective key from filtersInUrl list
            var filter = {};
            options.filtersInUrl.forEach( function(key, index) { 
                if (filter_list[index] !== undefined && filter_list[index] !== '_') {
                    filter[key] = [filter_list[index]];
                }
            });

            setFilter(filter);
        }

        // Replace window history state
        window.history.replaceState({filters: cur_filter}, '', '');

        // Trigger onFiltersFromUrlSet event
        setTimeout(function () { _hook('onFiltersFromUrlSet'); }, 1); 
    }

    // Set url depending on current filter
    function _setUrlFromFilter() {

        // Get current url without any filters
        var url = '';
        if (window.location.href.search('filter/') > -1) {
            url = window.location.href.substr(0, window.location.href.indexOf('filter/'));
        } else {
            url = window.location.href;
        }

        // Add all filters in a string separated by '/'
        // Add '_' to the filters in filtersInUrl that are not cur_filter 
        // if the last filter in filtersInUrl exist in cur_filter
        var filter_string = '';
        var list_of_inserted_filter_key = [];
        options.filtersInUrl.forEach( function(key, index) { 
            if (cur_filter.hasOwnProperty(key)) {
                filter_string += cur_filter[key] + '/';

                list_of_inserted_filter_key.push(key);
            } else {
                if (Object.keys(cur_filter).length > 0 && list_of_inserted_filter_key.indexOf(key) === -1 && Object.keys(cur_filter).length !== list_of_inserted_filter_key.length) {
                    filter_string += '_/';
                }
            }
        });

        if (filter_string !== '') {
            url = url + 'filter/' + filter_string;
        }

        // Push window history state
        window.history.pushState({filters: cur_filter}, '', url);
    }

    // Reset current page to 1
    function _resetPage() {
        cur_page = 1;
        itemsLoaded = false;
    }
    
    // Add "visible_by_filter" class to every grid item which should be visible based on cur_filter
    function _applyFilterClass() {

        // opt_in: All items are visible, class is removed in loop
        $el.find(".grid_item").addClass('visible_by_filter');
        
        // Get current isotope items (in isotope sort) 
        var cur_isotope_elems = $el.isotope('getFilteredItemElements');

        // Loop through current filters
        for (var key in cur_filter) {
            if (cur_filter.hasOwnProperty(key)) {

                // Loop through grid elements
                for ( var i in cur_isotope_elems ) {
                    var $cur_elem = $(cur_isotope_elems[i]);
                    var cur_attr_name = 'data-' + key;

                    // Is current filter attribute set?
                    if ($cur_elem.is('[' + cur_attr_name + ']')) {                        
                        var attr_data = $cur_elem.attr(cur_attr_name).split(",");
                        var hit = false;                 
                        
                        // Loop through current elements current filter values
                        for (var value_key in cur_filter[key]) {           
                            var filter_data = cur_filter[key][value_key].split(",");

                            for (var filter_key in filter_data) {   
                                if (attr_data.indexOf(filter_data[filter_key]) > -1) {
                                    hit = true;
                                }
                            }
                        }

                        // Hide element if current filter does not hit one of the filter values
                        if (!hit) {
                            $cur_elem.removeClass("visible_by_filter");
                        }
                    }
                    else {
                        // Hide all elements which do not have a data attribute with current filter
                        $cur_elem.removeClass("visible_by_filter");
                    }
                }

            }
        }
    }

    // Add "visible_by_page" class to every grid item which should be visible based on cur_page and options.itemsPerPage
    function _applyPaginationClass() {
        $el.find(".grid_item").removeClass('visible_by_page');

        var cur_isotope_elems = $el.isotope('getFilteredItemElements');   
        
        counter = 0;

        for ( var i in cur_isotope_elems ) {
            var $cur_elem = $(cur_isotope_elems[i]);

            if ($cur_elem.hasClass("visible_by_filter")) {
                $cur_elem.addClass("visible_by_page");   
                counter++; 
            }                        

            if (counter >= (options.itemsPerPage * cur_page)) {
                break;
            }
        }        

    }

    // Call _init function    
    _init();

    // Return public functions
    return {        
        destroy: destroy,
        getInstance: getInstance,
        setFilter: setFilter,
        getFilter: getFilter,
        clearFilter: clearFilter,
        addFilterValue: addFilterValue,
        loadMore: loadMore,        
        isMoreItemsAvailable: isMoreItemsAvailable,
        addItems: addItems,
        setMoreItemsAvailable: setMoreItemsAvailable,
    };
}

$.fn[pluginName] = function(options) {
    if (typeof arguments[0] === 'string') {
        var methodName = arguments[0];
        var args = Array.prototype.slice.call(arguments, 1);
        var returnVal;
        this.each(function() {
            if ($.data(this, 'plugin_' + pluginName) && typeof $.data(this, 'plugin_' + pluginName)[methodName] === 'function') {
                returnVal = $.data(this, 'plugin_' + pluginName)[methodName].apply(this, args);
            } else {
                throw new Error('Method ' +  methodName + ' does not exist on jQuery.' + pluginName);
            }
        });
        if (returnVal !== undefined){
            return returnVal;
        } else {
            return this;
        }
    } else if (typeof options === "object" || !options) {
        return this.each(function() {
            if (!$.data(this, 'plugin_' + pluginName)) {
                $.data(this, 'plugin_' + pluginName, new Plugin(this, options));
            }
        });
    }
};

$.fn[pluginName].defaults = {
    onInit: function() {},
    onDestroy: function() {},
    onArrangeComplete: function () {},
    onFiltersFromUrlSet: function () {},
    onApplyFilterStarted: function () {},
    onApplyFilterEnded: function () {},
    onLoadMoreStarted: function () {},
    onLoadMoreEnded: function() {},
    itemsPerPage: 8,
    filtersInUrl: [],
    getSortData: {},
};

})(jQuery);
