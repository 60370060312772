(function($) {


//******************************************************************************
//* Grid element
//******************************************************************************
dgmk_project_grid = {
    // TODO: adjust delay
    _SEARCH_TEXT_CHANGE_DELAY: 1000,

    last_rest_call_id: 0,

    init: function () {
        this._init_isotope();
        this._init_interface();
    },

    _init_isotope: function () {
        $(".project_grid .grid_wrapper").nd_filtered_isotope({
            filtersInUrl: ["topic", "topicModule", "search_text"],
            onArrangeComplete: function () {
                dgmk_project_grid._refreshLoadMoreButton($(this));

                var element = $(this);
                // apply same height group once all images are loaded
                $(this).imagesLoaded()
                    .always( function( instance ) {
                        same_height_groups.refresh();
                        element.isotope("layout");
                    });
            },
            onFiltersFromUrlSet: function () {
                dgmk_project_grid._refreshFilterButtonActiveness($(this));
                dgmk_project_grid._refreshTopicModuleSelect2List($(this));
                dgmk_project_grid._refreshTopicModuleSelect2SelectedValues($(this));
                dgmk_project_grid._refreshSearchTextField($(this));
            },
            onApplyFilterStarted: function() {
                $(this).find(".loading_pane").addClass("running");
                $(this).parents(".project_grid").find(".no_results_label").hide();
            },
            onApplyFilterEnded: function() {
                $(this).find(".loading_pane").removeClass("running");
            },
            onLoadMoreStarted: function() {
                $(this).parents(".project_grid").find(".show_more_button .loading_spinner").addClass("running");
            },
            onLoadMoreEnded: function() {
                $(this).parents(".project_grid").find(".show_more_button .loading_spinner").removeClass("running");
            },
            customFilter: function(filter, posts_per_page, cur_page) {

                // build REST route with filters
                var url = "/wp-json/filter/projects/";

                // FIXME:
                // the correct language-dependent URL should be built
                // programmatically without the need of multiple conditionals.
                // so we need information about if the current language
                // differs from the default language and if so, we need to
                // easily access the corresponding sub-path to prepend it.
                // if (document.documentElement.lang === "en-US") {
                if ($("body").hasClass("translatepress-en_US")) {
                    url = "/en" + url;
                }

                if (filter.topic !== undefined) {
                    url += filter.topic[0] + "/";
                } else {
                    url += "_/";
                }

                if (filter.topicModule !== undefined) {
                    url += filter.topicModule.join() + "/";
                } else {
                    url += "_/";
                }

                if (filter.search_text !== undefined) {
                    url += filter.search_text + "/";
                } else {
                    url += "_/";
                }

                // increment last rest call id
                dgmk_project_grid.last_rest_call_id++;

                // add page number and posts per page to URL as GET parameters
                url += "?" + "page=" + cur_page + "&" + "per_page=" + posts_per_page + "&" + "rest_call_id=" + dgmk_project_grid.last_rest_call_id;

                $.get(url, function(data, status) {
                    // TODO: get filtered isotope for concrete element
                    var filtered_isotope = $(".project_grid .grid_wrapper").nd_filtered_isotope("getInstance");
                    if (parseInt(data.rest_call_id) === dgmk_project_grid.last_rest_call_id) {
                        filtered_isotope.setMoreItemsAvailable(data.hasMoreItems);
                        filtered_isotope.addItems($(data.html));
                    }

                    var no_results_label = $(".project_grid .grid_wrapper .no_results_label");
                    if (data.html) {
                        no_results_label.hide();
                    } else {
                        no_results_label.show();
                    }
                });
            }
        });
    },

    _init_interface: function () {

        // Filter click
        $(".project_grid .filter_button").click(function () {
            var filtered_isotope = dgmk_project_grid._getFilteredIsotopeInstance($(this));
            var topic = $(this).attr("data-filter");
            var filter = filtered_isotope.getFilter();
            
            if ( topic === "all" ) {
                filtered_isotope.clearFilter(["topic", "topicModule"]);
            }
            else {
                filter.topic = [topic];
                delete filter.topicModule;
                filtered_isotope.setFilter(filter);
            }

            // Refresh active class of filter button
            dgmk_project_grid._refreshFilterButtonActiveness($(this));

            // Reinitialize module list dropdown with new list
            dgmk_project_grid._refreshTopicModuleSelect2List($(this));
        }); 

        // Show more
        $(".project_grid .show_more_button").click(function (e) {
            var filtered_isotope = dgmk_project_grid._getFilteredIsotopeInstance($(this));
            filtered_isotope.loadMore();
            e.preventDefault();
        });

        // Topic module list
        $(".project_grid .topic_module_list").select2({
            data: []
        });

        // On topic module select/unselect
        $(".project_grid .topic_module_list").on("select2:select select2:unselect", function(e) {
            var filtered_isotope = dgmk_project_grid._getFilteredIsotopeInstance($(this));
            var filter = filtered_isotope.getFilter();

            // On topic module dropdown filter change
            if ($(e.target).select2("val")) {
                filter.topicModule = [$(e.target).select2("val").join()];
                filtered_isotope.setFilter(filter);
            } else {
                filtered_isotope.clearFilter(["topicModule"]);
            }
        });

        var timeout;
        $(".project_grid [name='search_text']").on("paste keyup", function() {
            var filtered_isotope = dgmk_project_grid._getFilteredIsotopeInstance($(this));
            var filter = filtered_isotope.getFilter();

            var search_text = $(this).val();
            // encode search string and remove slashes to avoid server problems with encoded slashes in URL
            search_text = encodeURIComponent(search_text.replace(/\//g, ""));

            clearTimeout(timeout);
            timeout = setTimeout(function() {
                if (search_text) {
                    filter.search_text = search_text;
                } else {
                    delete filter.search_text;
                }
                filtered_isotope.setFilter(filter);
            }, dgmk_project_grid._SEARCH_TEXT_CHANGE_DELAY);
        });
    },

    _refreshTopicModuleSelect2List: function ($cur_elem) {
        var filtered_isotope = dgmk_project_grid._getFilteredIsotopeInstance($cur_elem);        
        cur_topic_filter = filtered_isotope.getFilter().topic; // Get current topic filter

        var topic_module_list = [];

        if (typeof php_to_js.project_grid_topic_modules !== 'undefined') {
            $.each(php_to_js.project_grid_topic_modules, function(index, topic_module){
                if (cur_topic_filter !== undefined) {
                    if (topic_module.topic === cur_topic_filter[0]) {
                        topic_module_list.push({
                            id: topic_module.slug,
                            text: topic_module.name
                        });
                    }
                } else {
                    topic_module_list.push({
                        id: topic_module.slug,
                        text: topic_module.name
                    });
                }
            });
        }

        $(".project_grid .topic_module_list").select2('destroy').empty();
        $(".project_grid .topic_module_list").select2({
            data: topic_module_list,
            placeholder: php_to_js.translations.SELECT_TECHNICAL_COMMITTEE_WORK_GROUP_TOPIC_MODULE
        });
    },

    _refreshLoadMoreButton: function ($cur_elem) {
        var filtered_isotope = dgmk_project_grid._getFilteredIsotopeInstance($cur_elem);        

        if (filtered_isotope.isMoreItemsAvailable()) {
            this._getGridMainElement($cur_elem).find(".show_more_button").show();
        }
        else {
            this._getGridMainElement($cur_elem).find(".show_more_button").hide();    
        }
    },

    _refreshFilterButtonActiveness: function ($cur_elem) {
        var filtered_isotope = dgmk_project_grid._getFilteredIsotopeInstance($cur_elem);        
        cur_topic_filter = filtered_isotope.getFilter().topic; // Get current topic filter

        var $grid =  this._getGridMainElement($cur_elem); // Get Grid element
        $grid.find(".filter_button_container").removeClass("active");

        // Topic filter
        if (cur_topic_filter !== undefined) {            
            // Set current filter button active
            var $active = $grid.find(".filter_button[data-filter='" + cur_topic_filter[0] + "']");
            if ($active.length) {
                $active.closest('.filter_button_container').addClass("active");
            }
        }
        else {
            // Set "All" button active
            $grid.find(".filter_button[data-filter='all']").closest('.filter_button_container').addClass("active");
        }
    },

    _refreshTopicModuleSelect2SelectedValues: function ($cur_elem) {
        var filtered_isotope = dgmk_project_grid._getFilteredIsotopeInstance($cur_elem);
        cur_topic_module_filter = filtered_isotope.getFilter().topicModule; // Get current topic module filter  
        
        if (cur_topic_module_filter !== undefined) {
            // Set current topic module filter
            var selected_topic_module = cur_topic_module_filter[0].split(',');
            $(".project_grid .topic_module_list").val(selected_topic_module).trigger('change');
        }
    },

    _refreshSearchTextField: function ($cur_elem) {
        var filtered_isotope = dgmk_project_grid._getFilteredIsotopeInstance($cur_elem);
        cur_search_text = filtered_isotope.getFilter().search_text;
        
        if (cur_search_text !== undefined) {
            var search_text = decodeURIComponent(cur_search_text);
            $(".project_grid [name='search_text']").val(search_text).trigger('change');
        }
    },

    _getGridMainElement: function ($cur_elem) {
        return $cur_elem.parents(".project_grid");
    },

    _getFilteredIsotopeInstance: function ($cur_elem) {
        return this._getGridMainElement($cur_elem).find(".grid_wrapper").nd_filtered_isotope("getInstance");
    }
};

})(jQuery);
