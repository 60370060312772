(function($) {

    nd_borlabs_cookie_initializer = {
        TIMEOUT: 10000,
        RETRY_DELAY: 500,
    
        init: function () {
            // do nothing if borlabs cookie is not active
            if (typeof BorlabsCookie === "undefined") {
                return;
            }
    
            this.time_elapsed = 0;
            this.poll_initialization();
        },
    
        poll_initialization: function() {
            var _this = this;
    
            // check if the cookie box exists in the DOM
            if ($("#BorlabsCookieBox").length) {
                // move the cookie box to the body and display it
                $("#BorlabsCookieBox")
                    .appendTo("body")
                    .css("display", "block")
                    .addClass("Fixed");
            } else {
                this.time_elapsed += this.RETRY_DELAY;
    
                if (this.time_elapsed >= this.TIMEOUT) {
                    return;
                }
    
                // continue polling
                setTimeout(function () {
                    _this.poll_initialization();
                }, this.RETRY_DELAY);
            }
        }
    };
    
    })(jQuery);
    