(function($) {

    //******************************************************************************
    //* Event Form Handling
    //******************************************************************************
    dgmk_event_form_handling = {
        init: function() {
            this._init_events();
        },

        _init_events: function() {
            $('[data-section="event_form"] form').submit(function() {
                dgmk_event_form_handling._refresh_visibility_of_validation_errors();
                
                // Let form submit depending on form error status
                if ($(this).find('.form-group').hasClass('has_error')) {
                    $('[data-field="js_error_message"]').show();
                    $('[data-field="php_error_message"]').hide();
                    return false;
                } else {
                    $('[data-field="js_error_message"]').hide();
                    return true;
                }
            });
        },

        _refresh_visibility_of_validation_errors: function() {
            
            // Remove all field errors
            $('[data-section="event_form"] form .form-group').removeClass('has_error');

            // Validate all fields
            $('[data-section="event_form"] form .form-group').each(function( index ) {
                var field_value;

                // Text
                if ($(this).find('input[type="text"]').length) {
                    field_value = $(this).find('input').val();
                
                // Textarea
                } else if ($(this).find('textarea').length) {       
                    field_value = $(this).find('textarea').val();
                // Checkbox
                } else if ($(this).find('input[type="checkbox"]').length) {
                    field_value = $(this).find('input:checked').serialize();

                // Select
                } else if ($(this).find('select').length) {
                    field_value = $(this).find('select').val();

                // Return empty
                } else {
                    return;
                }

                if ($(this).attr('data-required') && field_value === '') {
                    $(this).addClass('has_error');

                    // Scroll to form
                    $.smoothScroll({ 
                        speed: 1000,               
                        scrollTarget: '[data-anchor="event_form_section"]',
                        offset: scroll_to_anchor._get_offset()
                    });
                } 

            });

        }
    };

})(jQuery); 