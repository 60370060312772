(function($) {

//******************************************************************************
//* Font manipulation
//******************************************************************************
dgmk_font_manipulation = {

    init: function () {
        this._add_hexagon_element();
    },

   _add_hexagon_element: function () { 
        $('.fnt_hexagon_at_left_side').addClass('hide_circle').prepend( "<div class='hexagon topic-hexagon-color'></div>" );
    }
};

})(jQuery);