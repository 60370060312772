(function($) {

dgmk_news_grid = {
    init: function () {
        this._init_isotope();
        this._init_interface();
    },

    _init_isotope: function () {
        $(".news_grid .grid_wrapper").nd_filtered_isotope({
            filtersInUrl: ["categories"],
            onArrangeComplete: function () {
                dgmk_news_grid._refreshLoadMoreButton($(this));
            },
            onFiltersFromUrlSet: function () {
                dgmk_news_grid._refreshFilterButtonActiveness($(this));
            }
        });
    },

    _init_interface: function () {
        // Filter click
        $(".news_grid .filter_button").click(function () {
            var filtered_isotope = dgmk_news_grid._getFilteredIsotopeInstance($(this));
            var filter = $(this).attr("data-filter");
            
            if ( filter === "all" ) {
                filtered_isotope.clearFilter(["categories"]);
            }
            else {
                filtered_isotope.setFilter({
                    categories: [filter]
                });                
            } 

            // Refresh active class of filter button
            dgmk_news_grid._refreshFilterButtonActiveness($(this));          
        }); 

        // Show more
        $(".news_grid .show_more_button").click(function (e) {
            var filtered_isotope = dgmk_news_grid._getFilteredIsotopeInstance($(this));
            filtered_isotope.loadMore();
            e.preventDefault();
        });
    },

    _refreshLoadMoreButton: function ($cur_elem) {
        var filtered_isotope = dgmk_news_grid._getFilteredIsotopeInstance($cur_elem);        

        if (filtered_isotope.isMoreItemsAvailable()) {
            this._getGridMainElement($cur_elem).find(".show_more_button").show();
        }
        else {
            this._getGridMainElement($cur_elem).find(".show_more_button").hide();    
        }
    },

    _refreshFilterButtonActiveness: function ($cur_elem) {
        var filtered_isotope = dgmk_news_grid._getFilteredIsotopeInstance($cur_elem);        

        cur_category_filter = filtered_isotope.getFilter().categories; // Get current filter
        var $grid =  this._getGridMainElement($cur_elem); // Get Grid element
        $grid.find(".filter_button").removeClass("active");

        if (cur_category_filter !== undefined) {            
            // Set current filter button active
            var $active = $grid.find(".filter_button[data-filter='" + cur_category_filter[0] + "']");
            if ($active.length) {
                $active.addClass("active");                
            }
        }
        else {
            // Set "All" button active
            $grid.find(".filter_button[data-filter='all']").addClass("active");
        }
    },    

    _getGridMainElement: function ($cur_elem) {
        return $cur_elem.parents(".news_grid");
    },

    _getFilteredIsotopeInstance: function ($cur_elem) {
        return this._getGridMainElement($cur_elem).find(".grid_wrapper").nd_filtered_isotope("getInstance");
    }
};

})(jQuery);
