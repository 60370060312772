(function($) {



//******************************************************************************
//* Shopping cart
//******************************************************************************
dgmk_shopping_cart = {
    _apply_discount: 0,

    init: function () {
        this._init_events();
        this._update_individual_and_overall_price();
        this._refresh_publication_cart_list_and_checkout_form_visibility();
    },

    _init_events: function () {

        // Action on remove button click
        $('.wpb_content_element.shopping_cart [data-section="publication_list"] .remove_publication_button').click(function(event) {
            
            // Remove publication from cart
            $(this).closest('[data-section="publication"]').remove();
            dgmk_publication_shopping_cart.remove_publication($(this).closest('[data-section="publication"]').attr('data-publication-id'));

            // Update over all prices
            dgmk_shopping_cart._update_individual_and_overall_price();

            // Refresh publication cart list and checkout form visibility
            dgmk_shopping_cart._refresh_publication_cart_list_and_checkout_form_visibility();
        });

        // Action when amount is changed for a publication
        $('.wpb_content_element.shopping_cart [data-section="publication_list"] input[name="amount"]').on('input', function() {
            
            // Set updated amount to data attribute
            if ( $(this).val() ) {
                $(this).closest('[data-field="amount"]').attr('data-publication-amount', $(this).val());
            } else {
                $(this).closest('[data-field="amount"]').attr('data-publication-amount', 0);
            }

            var publication = {
                'id': $(this).closest('[data-section="publication"]').attr('data-publication-id'),
                'number': $(this).closest('[data-section="publication"]').attr('data-publication-number'),
                'name': $(this).closest('[data-section="publication"]').attr('data-publication-name'),
                'price': parseFloat($(this).closest('[data-section="publication"]').attr('data-publication-price')),
                'discounted_price': parseFloat($(this).closest('[data-section="publication"]').attr('data-publication-discounted-price')),
                'amount': parseFloat($(this).val()),
                'no_discount_for_dgmk_members': parseFloat($(this).closest('[data-section="publication"]').attr('data-no-discount-for-dgmk-members')),
                'disclaimer_required': parseFloat($(this).closest('[data-section="publication"]').attr('data-disclaimer-required')),
                'language': $(this).closest('[data-section="publication"]').attr('data-publication-language'),
            };

            // Update publication in cart
            dgmk_publication_shopping_cart.add_or_update_publication(publication);
            
            // Update over all prices
            dgmk_shopping_cart._update_individual_and_overall_price();
        });

        $('.wpb_content_element.shopping_cart [data-section="publication_list"] input[name="amount"]').keydown(function(event) {
            /*
            * 95-106 corresponds to Numpad 0 through 9;
            * 47-58 corresponds to 0 through 9 on the Number Row;
            * 8 is Backspace
            */
            if (!((event.keyCode > 95 && event.keyCode < 106) || (event.keyCode > 47 && event.keyCode < 58) || event.keyCode === 8)) {
                return false;
            }
        });

        // Action when discount checkbox is checked
        $('.wpb_content_element.shopping_cart [data-section="publication_list"] input[name="discount"]').change(function() {
            if (this.checked === true) {
                dgmk_shopping_cart._discount_enable();
            } else {
                dgmk_shopping_cart._discount_disable();
            }
        });
    },

    _update_individual_and_overall_price: function () {
        var overall_price = 0, price = 0, vat_price = 0, gross_price = 0;
        var price_selector = 'data-publication-price';

        if ( dgmk_shopping_cart._apply_discount ) {
            price_selector = 'data-publication-discounted-price';
        }

        $('.wpb_content_element.shopping_cart [data-section="publication_list"] [data-section="publication"]').each(function(indes, publication) {
            price = parseFloat($(publication).attr(price_selector)) * parseFloat($(publication).find('[data-field="amount"]').attr('data-publication-amount'));
            overall_price = overall_price + price;
            gross_price = overall_price * (parseFloat(php_to_js.shopping_cart_vat_percentage) + 100) / 100;
            vat_price = gross_price - overall_price;

            // Individual price update
            $(publication).find('[data-field="publication_total_price"]').text(dgmk_shopping_cart._format_price(price));
        });

        // Overall price update
        $('.wpb_content_element.shopping_cart [data-section="publication_list"] [data-field="overall_price"]').text(dgmk_shopping_cart._format_price(overall_price));
        $('.wpb_content_element.shopping_cart [data-section="publication_list"] [data-field="vat_price"]').text(dgmk_shopping_cart._format_price(vat_price));
        $('.wpb_content_element.shopping_cart [data-section="publication_list"] [data-field="gross_price"]').text(dgmk_shopping_cart._format_price(gross_price));

        // Update purchase form
        dgmk_shopping_cart._update_purchase_form_with_publications();
    },

    _discount_enable: function () {
        dgmk_shopping_cart._apply_discount = 1;
        $('.wpb_content_element.shopping_cart [data-section="publication_list"]').addClass('show_discount');
        dgmk_shopping_cart._update_individual_and_overall_price();
        
    },

    _discount_disable: function () {
        dgmk_shopping_cart._apply_discount = 0;
        $('.wpb_content_element.shopping_cart [data-section="publication_list"]').removeClass('show_discount');
        dgmk_shopping_cart._update_individual_and_overall_price();

    },

    _update_purchase_form_with_publications: function () {
        var publications = dgmk_publication_shopping_cart.get_publications_from_cookie();
        var is_member = !!dgmk_shopping_cart._apply_discount;

        var items = publications.map(function(publication, index) {
            var discounted_price;
            var sum = publication.price * publication.amount;
            var is_discountable = !publication.no_discount_for_dgmk_members;

            if (is_member && is_discountable) {
                discounted_price = dgmk_shopping_cart._format_price(publication.price/2);
                sum = publication.price/2 * publication.amount;
            }

            return {
                "id": publication.id,
                "number": publication.number,
                "name": publication.name,
                "regular_price": dgmk_shopping_cart._format_price(publication.price),
                "amount": publication.amount,
                "sum": dgmk_shopping_cart._format_price(sum),
                "discounted_price": discounted_price,
                "is_discountable": is_discountable,
                "language": publication.language
            };
        });

        // Add additional information
        
        // Add net_price, vat and gross_price to additional info
        var overall_price = $('.wpb_content_element.shopping_cart [data-section="publication_list"] [data-field="overall_price"]').text();
        var vat_price = $('.wpb_content_element.shopping_cart [data-section="publication_list"] [data-field="vat_price"]').text();
        var gross_price = $('.wpb_content_element.shopping_cart [data-section="publication_list"] [data-field="gross_price"]').text();

        var shopping_cart = {
            "items": items,
            "additional_info": {
                "is_member": is_member,
                "overall_price": overall_price,
                "vat_price": vat_price,
                "gross_price": gross_price
            }
        };

        // Set value in form field
        $(".wpb_content_element.shopping_cart [data-section='purchase_form'] input[data-field='data_publications']")
            .val(JSON.stringify(shopping_cart));
    },

    _format_price: function (price) {
        if (price >= 0) {
            return price.toFixed(2).replace(".", ",");    
        } else {
            return;
        }        
    },

    _refresh_publication_cart_list_and_checkout_form_visibility: function () {
        var publications = dgmk_publication_shopping_cart.get_publications_from_cookie();
        var $shopping_cart = $('.wpb_content_element.shopping_cart');

        if (publications.length === 0) {
            $shopping_cart.find('[data-section="publication_archive_content"]').removeClass('d-none');
            
            // Only hide list if purchase form is submitted
            if ($shopping_cart.find('[data-section="publication_list_and_purchase_form"]').hasClass('purchase_form_submitted')) {
                $shopping_cart.find('[data-section="publication_list"]').addClass('d-none');
            } else {
                $shopping_cart.find('[data-section="publication_list_and_purchase_form"]').addClass('d-none');
            }
        }
    }
};

})(jQuery);