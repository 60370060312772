(function($) {

    //******************************************************************************
    //* UAParser init
    //******************************************************************************
    uaparser = new UAParser();



    //******************************************************************************
    //* Browser and os detection
    //******************************************************************************
    dgmk_browser_and_os_detection = {
        init: function () {
            this._detect_ie();
            this._detect_ios();
        },

        _detect_ie: function () {
            if (uaparser.getBrowser().name === "IE") {
                $('body').addClass('dgmk_is_ie');
            } else {
                $('body').addClass('dgmk_is_not_ie');            
            }
            if (uaparser.getBrowser().name === "Edge") {
                $('body').addClass('dgmk_is_edge');
            } else {
                $('body').addClass('dgmk_is_not_edge');            
            }
        },

        _detect_ios: function () {
            if (uaparser.getOS().name === "iOS") {
                $('body').addClass('dgmk_is_ios');
            }
            else {
                $('body').addClass('dgmk_is_not_ios');            
            }
        }    
    };



    //******************************************************************************
    //* Tools
    //******************************************************************************
    dgmk_tools = {
        slugify: function (text) {
            return text.toString().toLowerCase()
                .replace(/\s+/g, '-')           // Replace spaces with -
                .replace(/[^\w\-]+/g, '')       // Remove all non-word chars
                .replace(/\-\-+/g, '-')         // Replace multiple - with single -
                .replace(/^-+/, '')             // Trim - from start of text
                .replace(/-+$/, '');            // Trim - from end of text
        }
    };



    //******************************************************************************
    //* Overlay Interface
    //******************************************************************************
    nd_overlay_interface = {
        current_hide_timeout: 0,

        init: function () {
            $('#scroll_up').on('click', function () {
                nd_overlay_interface._scrollToTop(); 
            });

            $(document).on('scroll', function() {
                nd_overlay_interface._refreshOverlayVisibility(); 
            });
        }, 

        _scrollToTop: function () {
            $.smoothScroll({
                scrollTarget: '#'
            });
        },

        _refreshOverlayVisibility: function() {
            var bodyScrollTop = document.documentElement.scrollTop || document.body.scrollTop;
            if ( bodyScrollTop > 100) {
                $('#scroll_up').addClass('visible');
                clearTimeout(nd_overlay_interface.current_hide_timeout);
                nd_overlay_interface.current_hide_timeout = setTimeout(function () { 
                    $('#scroll_up').removeClass('visible');
                }, 3000);
            }
            else {
                $('#scroll_up').removeClass('visible');
            }
        },
    };



    //******************************************************************************
    //* Enable bootstrap tooltips
    //******************************************************************************
    nd_tooltip = {
        init: function () {
            $('.price [data-toggle="tooltip"],[data-field="price"] [data-toggle="tooltip"]').tooltip();
        }
    };


    //******************************************************************************
    //* Post Number and Breadcrumb spacing
    //******************************************************************************
    nd_breadcrumb_spacing = {
        init: function () {
            nd_breadcrumb_spacing.adjust_height();

            $(window).on('nd_resize_ready', function() {
                nd_breadcrumb_spacing.adjust_height();
            });
        },

        adjust_height: function () {
            var $top_bar = $('.top_bar_wrapper');
            var height =  $('.top_bar_wrapper').outerHeight();
            var $parent = $top_bar.parent();

            $parent.css('padding-top', height);
        }
    };    


})(jQuery); 
